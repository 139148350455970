//import logo from './logo.svg';

//import 'react-alice-carousel/lib/alice-carousel.css';
import './App.css';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import normalizeUrl from 'normalize-url';
normalizeUrl('https://www.aquatechnics.com.au');
function App() {
  return (
    <div className="App">
              <HelmetProvider>

<Helmet>
    <html lang="en" />
    <title>Aqua Technics – Fibreglass Swimming Pools</title>
    <link rel="canonical" href="https://www.aquatechnics.com.au/" />
    <meta charSet="utf-8" />
    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Swimming Pools Australia - Aqua Technics has been manufacturing award-winning fibreglass swimming pools since 1976. Whether you are in Perth WA, Melbourne VIC, Sydney NSW, Brisbane QLD, Adelaide SA or Canberra ACT you can now own the highest quality swimming pool available. We have the best range of technologically advanced fibreglass pools at the best prices. Request a quote now!" />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool Perth, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools,  Buy swimming pool, Fiberglass pools, Fiberglass pools WA, Fibreglass Swimming Pools Western Australia, WA, Australia, Perth,  Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Australia, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote" />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>

</HelmetProvider>
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;

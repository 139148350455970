import React from "react";
import './Home.css';

import awards from './img/Most-Awarded-Banner_4.webp';
import Fibreglass from "./Fibreglass_Swimming_Pool";
import AusNetwork from "./AT_Australia_Network";
import Slider from "./Slider_steps";
import {Helmet} from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import './Media.css';
function Home() {
    return (
        <frameElement>
          <HelmetProvider>

<Helmet>
    <html lang="en" />
    <title>Aqua Technics – Fibreglass Swimming Pools</title>
    <link rel="canonical" href="https://www.aquatechnics.com.au/" />
    <meta charSet="utf-8" />
    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Swimming Pools Australia - Aqua Technics has been manufacturing award-winning fibreglass swimming pools since 1976. Whether you are in Perth WA, Melbourne VIC, Sydney NSW, Brisbane QLD, Adelaide SA or Canberra ACT you can now own the highest quality swimming pool available. We have the best range of technologically advanced fibreglass pools at the best prices. Request a quote now!" />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool Perth, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools,  Buy swimming pool, Fiberglass pools, Fiberglass pools WA, Fibreglass Swimming Pools Western Australia, WA, Australia, Perth,  Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Australia, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote" />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>

</HelmetProvider>
            <Fibreglass />
            <div className="awardPanel">
                <div className="award-col-left"><h2>Australia’s most awarded pool company</h2></div>
                <div className="award-col-right"><img src={awards} className="award" alt="Australia’s most awarded pool company" /></div>
            </div>


            <AusNetwork />


            <Slider />

        </frameElement>
    )
}

export default Home;